import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image/withIEPolyfill"
import { FluidImage } from "@components/Image"
import { useTranslation } from 'react-i18next'

import Link from "@components/Link"

import css from "@css/components/blocks/gallery/OfficialMaterial.module.styl"

export default function GalleryOfficialMaterial()
{
    const {t} = useTranslation()
    const { miraiImg } = useStaticQuery(graphql`
        query GalleryOfficialMaterialQuery {
            miraiImg : file(relativePath: { eq: "Gallery__img__official-material@2x.png" }) {
                childImageSharp {
                    fluid(maxWidth: 263) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return(
        <div className={css.container}>
            <div className={css.card}>
                <figure className={css.thumbnail}>
                    <FluidImage data={miraiImg} />
                </figure>
                <div className={css.detail}>
                    <h2 className={css.title}>
                        <span className={css.l1}>{t('gallery__officialMaterialTitle1')}</span>
                        <span className={css.l2}>{t('gallery__officialMaterialTitle2')}</span>
                    </h2>
                    <p className={css.text} dangerouslySetInnerHTML={{__html: t('gallery__officialMaterialText1')}}/>
                    <Link className={css.button} to="/download">{t('gallery__officialMaterialButton1')}</Link>
                </div>
            </div>
        </div>
    )
}
