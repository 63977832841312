import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Image from "gatsby-image/withIEPolyfill"
import Link from "@components/Link"

import { useStores } from "@hooks/useStores"
// import Link from "@components/Link"

import css from "@css/components/ContentList.module.styl"

let $
if( typeof window !== 'undefined'){
    $ = require("jquery")
    window.jQuery = $
    require("@fancyapps/fancybox")
    require("@fancyapps/fancybox/dist/jquery.fancybox.min.css")
}

export default function ContentList({ data, isThumbnailTitle = true, type = 'gallary' , isDownload, isIllustDetail})
{
    const q = useStaticQuery(graphql`
        query {
            search : file(relativePath: { eq: "GlobalContentList__icn__search.svg" }) {
                publicURL
            }
            link : file(relativePath: { eq: "GlobalContentList__icn__link.svg" }) {
                publicURL
            }
            link_green : file(relativePath: { eq: "Common__icn__link--green.svg" }) {
                publicURL
            }

            thumbnail_brank : file(relativePath: { eq: "Common__img__thumbnail-brank@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                } }
                publicURL
            }
        }
    `)
    const { appStore } = useStores()
    const [isModal, setIsModal] = React.useState(false)
    const selectIdRef = React.useRef(null)

    React.useEffect(()=>{
        if( !$ ){ return }
        let fancyParent
        $(`[data-fancybox="${type}"]`).fancybox({
            hash: false,
            buttons: [
                "zoom",
                //"share",
                "slideShow",
                //"fullScreen",
                isDownload && "download",
                // "thumbs",
                "close"
            ],
            beforeShow: function (instance, current) {
                appStore.isPopstate = false
            },
            afterClose: function (e) {
                appStore.isPopstate = true
            }
        });
    },[])

    return(
        <React.Fragment>
        { data.length !== 0 ?
        <ul className={css.container}>
            {
                data.map(( {node}, i)=>{
                    const { title, featured_media, acf, path, content } = node
                    if( !featured_media ){
                        console.log("debug",title, node)
                        return null
                    }
                    // console.log(featured_media)
                    let dom = (i) => <div key={i}/>

                    const Cover = () => (
                        <div className={css.cover}>
                            {isThumbnailTitle ?
                                <div className={css.title}>
                                    <div dangerouslySetInnerHTML={{ __html: title }} />
                                </div>
                                :
                                <span className={css.icon}>
                                    <img src={q.search.publicURL} alt="" />
                                </span>
                            }
                        </div>
                    )

                    if (type === 'download') {
                        console.log('dl',node)
                        dom = (i) => (
                            <a className={css.item} key={i} data-fancybox={type} href={acf.download_link_url1}>
                                <figure className={css.thumbnail}>
                                    <Image fluid={featured_media.localFile.childImageSharp.fluid} />
                                </figure>
                                <Cover />
                            </a>
                        )
                    }

                    if (type.match('illust') ){
                        console.log('test f',featured_media)
                        dom = (i) => (
                            <div key={i} className={css.item}>
                                <a data-fancybox={type}
                                    href={featured_media.localFile.childImageSharp.thumbnail}
                                    data-srcset={featured_media.localFile.childImageSharp.middle.srcSet}
                                >
                                    <figure className={css.thumbnail}>
                                        {/* <Image fluid={featured_media.localFile.childImageSharp.thumbnail} /> */}
                                        <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail}/>
                                    </figure>
                                    <Cover/>
                                </a>
                                { ( isIllustDetail && ( acf.artist_name || acf.artist_url) ) &&
                                    <div className={css.artistDetail}>
                                        {acf.artist_name && <h4 className={css.artistName}>{acf.artist_name}</h4> }
                                        {acf.artist_url && <p className={css.artistUrl}>
                                            <a href={acf.artist_url} target="_blank">{ acf.artist_url_label ? acf.artist_url_label : acf.artist_url}<span className={css.icon}>
                                                <img src={q.link_green.publicURL}/>
                                            </span></a>
                                        </p>}
                                    </div>
                                }
                            </div>
                        )
                    }

                    if (type === 'movie') {
                        // console.log(node)
                        dom = (i) => {
                            let url = `https://www.youtube.com/watch?v=${acf.movie_youtube_id}?rel=0`
                            return (
                                <a className={css.item} key={i} data-fancybox={type} href={url} data-caption={`
                                    <div style="font-size:0.12rem; font-weight: bold;">${title}</div>
                                    <div style="font-size:0.10rem;">${content}</div>`}>
                                    <figure className={css.thumbnail}>
                                        <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail} />
                                    </figure>
                                    <Cover />
                                </a>
                            )
                        }
                    }

                    if( type=== 'comics'){
                        // console.log(path)
                        dom = (i) => (
                            <li className={css.item} key={i}>
                                <Link to={path}>
                                    <figure className={css.thumbnail}>
                                        <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail} />
                                    </figure>
                                    <Cover />
                                </Link>
                            </li>
                        )
                    }
                    return dom(i)
                })
            }
        </ul>
        :
        <ul className={css.container}>
            <li className={css.nopost}>投稿はまだありません。</li>
        </ul>
        }
        </React.Fragment>
    )
}
