import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useTranslation } from 'react-i18next'

import SEO from "@components/Seo"
import FooterSns from "@components/blocks/global/FooterSNS"
import Footer from "@components/blocks/global/Footer"
// import Header from "@components/blocks/global/Header"
import PageHeader from "@components/blocks/common/PageHeader"
import PageSectionTitle from "@components/blocks/common/PageSectionTitle"
import OfficialMaterial from "@components/blocks/gallery/OfficialMaterial"

import ContentListV2 from "@components/ContentList-v2"
import ContentList from "@components/blocks/comic/ContentList"

import css from "@css/components/blocks/common/Page.module.styl"

export default function Gallery({data})
{
    const {t} = useTranslation()
	const { allWordpressWpIllust, allWordpressWpComics, allWordpressWpMovie } = data
	const illustData = allWordpressWpIllust.edges
	const comicsData = allWordpressWpComics.edges
	const movieData = allWordpressWpMovie.edges
	console.log('testData',comicsData)

	return (
		<React.Fragment>
			<SEO title={'GALLERY'} />
			<main className={css.container} id="main">
				<PageHeader l1="GALLERY" l2={t('gallery__headerTitle2')}/>
				<div className={css.content}>
					<div className={css.topText}>
						{t('gallery__headerSubTitle1')}
					</div>
					<OfficialMaterial/>
					<div className={css.gallery}>
						<section className={css.section} id="illust">
							<PageSectionTitle l1={t('gallery__sectionTitle1-1')} l2={t('gallery__sectionTitle1-2')}/>
							<ContentListV2 type="illust" data={illustData} isThumbnailTitle={false}/>
						</section>
						<section className={css.section} id="movie">
							<PageSectionTitle l1={t('gallery__sectionTitle2-1')} l2={t('gallery__sectionTitle2-2')}/>
							<ContentListV2 type="movie" data={movieData} isThumbnailTitle={true} />
						</section>
						<section className={css.section} id="comics">
							<PageSectionTitle l1={t('gallery__sectionTitle3-1')} l2={t('gallery__sectionTitle3-2')}/>
							<ContentList data={comicsData} noTitle />
						</section>
					</div>
				</div>
			</main>
			<FooterSns/>
			<Footer/>
		</React.Fragment>
	)
}

export const pageQuery = graphql`
    query {
        allWordpressWpIllust (sort: { fields: date, order: DESC }){
            edges {
            	node {
					acf {
						type
						artist_name
						artist_url
						artist_url_label
					}
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                high: fluid(maxWidth: 1440) {
                                    ...GatsbyImageSharpFluid
								}
								middle: fluid(maxWidth: 720) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 480, cropFocus: CENTER, fit: COVER) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
            	}
            }
        }
        allWordpressWpMovie (sort: { fields: date, order: DESC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
            		date(formatString: "MMMM DD, YYYY")
            		featured_media {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        movie_youtube_id
                    }
            	}
            }
        }
        allWordpressWpComics (sort: { fields: date, order: ASC }) {
            edges {
            	node {
            		id
            		slug
            		title
            		content
					date(formatString: "MMMM DD, YYYY")
					path
            		featured_media {
            			source_url
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1280) {
                                    ...GatsbyImageSharpFluid
                                }
                                thumbnail: fluid(maxWidth: 640) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
            		}
                    acf {
                        comics_url
                    }
            	}
            }
        }
    }
`