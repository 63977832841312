import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FluidImage } from "@components/Image"
import Link from "@components/Link"

import { useStores } from "@hooks/useStores"
// import Link from "@components/Link"

import css from "@css/components/blocks/comic/ContentList.module.styl"

let $
if( typeof window !== 'undefined'){
    $ = require("jquery")
    window.jQuery = $
    require("@fancyapps/fancybox")
    require("@fancyapps/fancybox/dist/jquery.fancybox.min.css")
}

export default function ContentList({ data, disableCharacterLink, noTitle})
{
    const q = useStaticQuery(graphql`
        query {
            search : file(relativePath: { eq: "GlobalContentList__icn__search.svg" }) {
                publicURL
            }
            link : file(relativePath: { eq: "GlobalContentList__icn__link.svg" }) {
                publicURL
            }

            thumbnail_brank : file(relativePath: { eq: "Common__img__thumbnail-brank@2x.png" }) {
                childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                } }
                publicURL
            }
            character : file(relativePath: { eq: "Comic__img__character@2x.jpg" }) { childImageSharp { fluid(maxWidth: 640) {
                    ...GatsbyImageSharpFluid
                } }
            }
        }
    `)
    const { appStore } = useStores()
    const [isModal, setIsModal] = React.useState(false)
    const selectIdRef = React.useRef(null)

    // const Cover = () => (
    //     <div className={css.cover}>
    //         {isThumbnailTitle ?
    //             <div className={css.title}>
    //                 <div dangerouslySetInnerHTML={{ __html: title }} />
    //             </div>
    //             :
    //             <span className={css.icon}>
    //                 <img src={q.search.publicURL} alt="" />
    //             </span>
    //         }
    //     </div>
    // )

    return(
        <React.Fragment>
        { data.length !== 0 ?
        <ul className={ noTitle ? css.containerNoTitle : css.container }>
            { !disableCharacterLink &&
                <li className={css.item}>
                    <Link className={css.inner} to={"/comics/character/"}>
                        <div className={css.title}>
                            <span>キャラクター紹介</span>
                        </div>
                        <figure className={css.thumbnail}>
                            <FluidImage data={q.character}/>
                        </figure>
                    </Link>
                </li>
            }
            {
                data.map(( {node}, i)=>{
                    const { title, featured_media, acf, path, content } = node
                    // console.log(featured_media)
                    if( !featured_media ){
                        return null
                    }
                    return(
                        <li className={css.item} key={i}>
                            <Link className={css.inner} to={path}>
                                <div className={css.title}>
                                    <span>{title}</span>
                                </div>
                                <figure className={css.thumbnail}>
                                    <FluidImage fluid={featured_media.localFile.childImageSharp.thumbnail} />
                                </figure>
                            </Link>
                        </li>
                    )
                })
            }
        </ul>
        :
        <ul className={css.container}>
            <li className={css.nopost}>投稿はまだありません。</li>
        </ul>
        }
        </React.Fragment>
    )
}
